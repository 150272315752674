import { rem } from "polished";

const theme = {
  // COLOURS
  white: `#ffffff`,
  blue: `#0088E9`,
  blueDark: `#12253C`,
  grey: `#EBEBEB`,
  greyLightest: `#F9F9F9`,
  greyLight: `#999999`,
  greyDark: `#666666`,
  greyDarkFaded: `rgba(	102, 102, 102, 0.5)`,
  green: `#30B842`,
  greenDark: `#2FA73D`,
  purple: `#6529CC`,
  yellow: `#F7A600`,
  red: `#F23F2D`,
  pink: `#D9347E`,
  orange: `#F46A01`,

  // FONTS
  headingFont: `'display-lucky', 'Helvetica', sans-serif`,

  baseFont: `'regular-lucky', 'Helvetica', sans-serif`,
  baseFontSize: `18px`,
  baseFontSizeTabMob: rem(`18px`, `18px`),
  baseFontSizeDesktop: rem(`20px`, `18px`),
  baseFontLineHeight: `32px`,

  largeFontSize: rem(`40px`, `18px`),
  mediumFontSize: rem(`24px`, `18px`),
  smallFontSize: rem(`16px`, `18px`),
  smallestFontSize: rem(`12px`, `18px`),

  // BORDER-RADIUS
  borderRadiusSqBtn: `3px`,
  borderRadiusRndBtn: `30px`,
  borderRadiusContent: `10px`,

  // BREAKPOINTS
  screenXS: `375px`,
  screenS: `600px`,
  screenM: `800px`,
  screenL: `1024px`,
  screenXL: `1200px`,
  screenXXL: `1600px`,
  // Breakpoints array for Rebass (https://github.com/rebassjs/grid#theming)
  breakpoints: [`600px`, `800px`, `1024px`, `1200px`, `1600px`],

  maxContentWidth: `1200px`,

  btnPadS: `10px 20px`,
  btnPadL: `14px 20px`,

  // SPACE
  space6Point: [6, 12, 18, 24, 30, 36],
  space8Point: [8, 16, 24, 32, 40, 48],

  // TRANSITIONS
  transitionDefault: `all 0.25s ease`,
  contentFade: `all .6s`,
};

export default theme;
